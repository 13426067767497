@import url("https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

.container {
  display: flex;
  flex: 1;

  background-image: url("./assets/BackMaiara.png");
  background-position: center;
  background-size: cover;
  padding-bottom: 20px;
}

.information {
  flex: 2;
}

.Logo {
  display: flex;

  padding: 30px 6%;
}

.Logo img {
  width: 80px;
}

.message {
  font-family: "Rethink Sans", sans-serif;

  padding: 0 6%;

  display: flex;
  flex-direction: column;
}

.medium {
  font-size: 3.3rem;
  color: #fff;
}

.big {
  font-size: 8rem;
  color: #ffbd7c;
  font-weight: bold;
}

.small {
  font-size: 2.5rem;
  color: #fff;
}

.tiny {
  font-size: 2rem;
  color: #fff;
}

.paddingMessage {
  padding: 20px 0;
}

.interage {
  display: flex;
  padding: 0 6%;
}

.interage button {
  width: 80%;

  padding: 20px 0;
  border-radius: 7px;

  background-color: #af572a;
  color: #fff;
  font-family: "Rethink Sans", sans-serif;
  font-size: 1.3rem;

  border: 0;

  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
}

.interage button:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.share {
  flex: 1;
}







@media screen and (max-width: 1024px) {
  .medium {
    font-size: 3rem;
    color: #fff;
  }

  .big {
    font-size: 7rem;
    color: #ffbd7c;
    font-weight: bold;
  }

  .small {
    font-size: 2.2rem;
    color: #fff;
  }

  .tiny {
    font-size: 1.7rem;
    color: #fff;
  }
}



@media screen and (max-width: 650px) {
  .medium {
    font-size: 2.5rem;
    color: #fff;
  }

  .big {
    font-size: 5rem;
    color: #ffbd7c;
    font-weight: bold;
  }

  .small {
    font-size: 1.9rem;
    color: #fff;
  }

  .tiny {
    font-size: 1.5rem;
    color: #fff;
  }

  .interage button{
  font-size: 1.1rem;

  }



  .share{
    display: none;
  }

  .Logo img {
    width: 65px;
  }
}


@media screen and (max-width: 480px) {
  .medium {
    font-size: 2.3rem;
    color: #fff;
  }

  .big {
    font-size: 4rem;
    color: #ffbd7c;
    font-weight: bold;
  }

  .small {
    font-size: 1.6rem;
    color: #fff;
  }

  .tiny {
    font-size: 1.3rem;
    color: #fff;
  }

  .interage button{
  font-size: 1.1rem;

  }


  .Logo img {
    width: 50px;
  }
}


@media screen and (max-width: 375px) {
  .medium {
    font-size: 2.1rem;
    color: #fff;
  }

  .big {
    font-size: 3rem;
    color: #ffbd7c;
    font-weight: bold;
  }

  .small {
    font-size: 1.3rem;
    color: #fff;
  }

  .tiny {
    font-size: 1rem;
    color: #fff;
  }

  .interage button{
  font-size: .9rem;
  }


  .Logo img {
    width: 50px;
  }
}
*{
  margin: 0;
  padding: 0;


  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;


}





html,body,#root{
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;
}


#root::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

#root::-webkit-scrollbar-track {
  background: #c8c7c7;        /* color of the tracking area */
}

#root::-webkit-scrollbar-thumb {
  background-color: gray;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #c8c7c7;  /* creates padding around scroll thumb */
}